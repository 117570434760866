import videos from './components/VideoLibrary/videos';

const VideoService = {
  getVideo: id => {
    return videos[id];
  },
  getAllVideos: () => {
    return videos;
  },
  getPreviousVideo: currentVideo => {
    if (currentVideo.id - 1 < 0) {
      const previousVideo = videos[videos.length - 1];
      return previousVideo;
    }
    const previousVideo = videos[currentVideo.id - 1];
    return previousVideo;
  },
  getNextVideo: currentVideo => {
    if (currentVideo.id + 1 === videos.length) {
      const nextVideo = videos[0];
      return nextVideo;
    }
    const nextVideo = videos[currentVideo.id + 1];
    return nextVideo;
  },
  getOtherVideos: currentVideo => {
    const otherVideos = videos.filter(video => video.id !== currentVideo.id);
    return otherVideos;
  }
};
export default VideoService;
