import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvestNow.module.css';

const Docusign = props => {
  const { url } = props;
  return (
    <div className={styles.body}>
      <div className={styles.title}>
        <h1>Let&apos;s discover your investment profile</h1>
        <h3 className={styles.subtitle}>
          Let’s schedule a call with our Investor Relations team to better understand your needs
        </h3>
      </div>
      <iframe title="UniqueDocusign" src={url} className={styles.docusign} />
    </div>
  );
};

Docusign.propTypes = {
  url: PropTypes.string
};
Docusign.defaultProps = {
  url: ''
};

export default Docusign;
