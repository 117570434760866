import React from 'react';
import PropTypes from 'prop-types';
import styles from './VideoLibrary.module.css';

const NavigationArrow = props => {
  const { changeVideo, image } = props;
  return (
    <button type="button" className={styles.leftArrow} onClick={e => changeVideo(e)}>
      <img src={image} alt="" className={styles.arrowImage} />
    </button>
  );
};
NavigationArrow.propTypes = {
  changeVideo: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired
};
export default NavigationArrow;
