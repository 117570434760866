import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './MenuDesktop.module.css';
import fullLogo from '../../../images/fullLogo.svg';
import { setInvestNowClass, setOthersClass } from '../../../helpers/menuHelpers';

const MenuDesktop = props => {
  const { location } = props;

  const returnPath = path => {
    if (path === location.pathname) {
      window.location.reload();
      return path;
    }
    return path;
  };
  const setMenuItem = (path, label) => {
    return (
      <Link
        onClick={() => returnPath(path)}
        className={
          path === '/InvestNow'
            ? setInvestNowClass(path, location, styles)
            : setOthersClass(path, location, styles)
        }
        to={path}
      >
        {label}
      </Link>
    );
  };
  const renderLogo = () => {
    return (
      <Link className={styles.logo} to="/">
        <span>
          <img src={fullLogo} alt="" />
        </span>
      </Link>
    );
  };
  return (
    <div className={styles.container}>
      {renderLogo()}
      {setMenuItem('/InvestorPresentation', 'INVESTOR PRESENTATION')}
      {setMenuItem('/VideoLibrary', 'VIDEO LIBRARY')}
      {setMenuItem('/ContactUs', 'CONTACT US')}
      {setMenuItem('/InvestNow', 'INVEST NOW')}
    </div>
  );
};
MenuDesktop.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired
};
export default withRouter(MenuDesktop);
