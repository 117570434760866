import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvestNow.module.css';
import vector from '../../images/Vector.svg';
import backvector from '../../images/backvector.svg';

const MobileButtons = props => {
  const { goBack, prevQuestion, url, isEnabled, goToLandpage } = props;
  return (
    <div className={styles.mobileButtonsContainer}>
      <div className={styles.mobilestartInvestmentContainer}>
        <button
          type="button"
          onClick={goBack}
          className={prevQuestion.length === 0 ? styles.mobileBackNotEnabled : styles.mobileEnabled}
          disabled={prevQuestion.length === 0}
        >
          <img className={styles.backVector} src={backvector} alt="" />
        </button>
      </div>
      <div className={styles.mobileStartInvestmentContainer}>
        <a className={styles.docusignLink} href={url} target="_blank" rel="noopener noreferrer">
          <button
            type="button"
            onClick={goToLandpage}
            className={!isEnabled ? styles.mobileNotEnabled : styles.mobileStartEnabled}
            disabled={!isEnabled}
          >
            <img className={styles.vector} src={vector} alt="" />
          </button>
        </a>
      </div>
    </div>
  );
};

MobileButtons.propTypes = {
  isEnabled: PropTypes.bool,
  prevQuestion: PropTypes.arrayOf(Object),
  goBack: PropTypes.func,
  url: PropTypes.string,
  goToLandpage: PropTypes.func
};
MobileButtons.defaultProps = {
  isEnabled: false,
  prevQuestion: [],
  goBack: () => {},
  url: '',
  goToLandpage: () => {}
};

export default MobileButtons;
