import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContactUs.module.css';

const Frame = props => {
  const { url } = props;
  return <iframe title="frame" src={url} className={styles.iframe} />;
};
Frame.propTypes = {
  url: PropTypes.string.isRequired
};
export default Frame;
