import React, { Component } from 'react';
import styles from './ContactUs.module.css';
import line from '../../images/videoLibraryLine.svg';
import Frame from './Frame';
import backVector from '../../images/backvector.svg';

class ContactUs extends Component {
  state = {
    optionSelected: false,
    url: ''
  }

  selectOption = (url) =>{
    this.setState({optionSelected: true});
    this.setState({url});
  }

  header = () =>{
    return(
      <div className={styles.header}>
        <img className={styles.line} src={line} alt="" />
        <span className={styles.text}>Contact Us</span>
        <button onClick={()=>window.location.reload()}className={this.state.optionSelected ? styles.goBackButton : styles.hide}><img src={backVector} alt=""></img>GO BACK</button>
      </div>)
  }

  subtitle = () =>{
    return <h2 className={ this.state.optionSelected ? styles.hide : styles.subtitle}>Choose any option</h2>
  }

  optionButtons = () =>{
    return(
      <div className={styles.buttonsContainer}>
        <button onClick={() => this.selectOption('https://calendly.com/hgriffis')} className={styles.optionButton}>SCHEDULE CALL</button>
        <button onClick={() => this.selectOption('https://investdirect.typeform.com/to/Usu2qb')} className={styles.optionButton}>SUBMIT QUESTION</button>
      </div>
    )
  }


  body = () =>{
    return this.state.optionSelected ?  <Frame url={this.state.url} /> : this.optionButtons()
  }
  
  render() {
    return (
      <div className={styles.container}>
        {this.header()}
        {this.subtitle()}
        {this.body()}
      </div>
    );
  }
}

export default ContactUs;
