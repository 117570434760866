const questions = {
  'question-1': {
    question: 'What amout would you like to invest in Fund V?',
    questionNum: '1- ',
    options: [
      { value: 'less 100k', name: 'question-1', letter: 'A', text: ' Less than $100K' },
      { value: '100k - 249k', name: 'question-1', letter: 'B', text: ' $100K - $249K' },
      { value: '250 - 1M', name: 'question-1', letter: 'C', text: '$250K -$1M' },
      { value: '1M+', name: 'question-1', letter: 'D', text: '$1M+' }
    ]
  },
  'question-2': {
    question: 'How will you be investing?',
    questionNum: '2- ',
    options: [
      { value: 'individual', name: 'question-2', letter: 'A', text: ' Individual' },
      { value: 'entity', name: 'question-2', letter: 'B', text: ' Entity' }
    ]
  },
  'question-2b': {
    question: 'How will you be investing?',
    questionNum: '2- ',
    options: [
      { value: 'individual', name: 'question-2b', letter: 'A', text: ' Individual' },
      { value: 'entityOver250', name: 'question-2b', letter: 'B', text: ' Entity' }
    ]
  },
  'question-3': {
    question: 'Which type?',
    questionNum: '3- ',
    options: [
      { value: 'Corp or LLC', name: 'question-3', letter: 'A', text: ' Corp or LLC' },
      { value: 'Trust', name: 'question-3', letter: 'B', text: ' Trust' },
      { value: 'Partnership', name: 'question-3', letter: 'C', text: ' Partnership' }
    ]
  },
  'question-4': {
    question: 'Do you need an additional signer?',
    questionNum: '4- ',
    options: [
      { value: '2signers', name: 'question-4', letter: 'Y', text: ' Yes' },
      { value: '1signer', name: 'question-4', letter: 'N', text: ' No' }
    ]
  }
};
export default questions;
