import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvestNow.module.css';

const QuestionComponent = props => {
  const { options, question, questionNum, onChange, values } = props;
  const isChecked = opt => {
    return opt.value === values[questionNum - 1];
  };

  return (
    <div className={styles.radioButtonContainer}>
      <div className={styles.questionContainer}>
        <span className={styles.numQuestion}>{`${questionNum}- `}</span>
        &nbsp; &nbsp;
        <span className={styles.question}>{question}</span>
      </div>
      <div className={styles.containerRadio}>
        {options.map(opt => (
          <label key={opt.value} htmlFor={opt.value} className={styles.radio}>
            <button type="button" className={isChecked(opt) ? styles.checked : styles.notChecked}>
              <input
                type="radio"
                value={opt.value}
                onChange={onChange}
                name={opt.name}
                id={opt.value}
                required="required"
              />
              <strong>{opt.letter}</strong>
              <span className={styles.buttonText}>{opt.text}</span>
            </button>
          </label>
        ))}
      </div>
    </div>
  );
};

QuestionComponent.propTypes = {
  options: PropTypes.arrayOf(Object),
  question: PropTypes.string,
  questionNum: PropTypes.number,
  onChange: PropTypes.func,
  values: PropTypes.instanceOf(Object)
};
QuestionComponent.defaultProps = {
  options: [],
  question: '',
  questionNum: 0,
  onChange: () => {},
  values: null
};

export default QuestionComponent;
