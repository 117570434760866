const questionDictionary = {
  'less 100k': { next: 'question-2' },
  '100k - 249k': { next: 'question-2' },
  '250 - 1M': { next: 'question-2b' },
  '1M+': { next: 'A' },
  individual: { next: 'question-4' },
  entity: { next: 'question-3' },
  'Corp or LLC': { next: 'question-4' },
  Trust: { next: 'question-4' },
  Partnership: { next: 'question-4' },
  entityOver250: { next: 'question-4' }
};

export default questionDictionary;
