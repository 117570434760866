import React from 'react';
import styles from './InvestorPresentation.module.css';
import line from '../../images/videoLibraryLine.svg';

const InvestorPresentation = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.line} src={line} alt="" />
        <span className={styles.text}>Investor Presentation</span>
      </div>
      <iframe
        src="https://www.slideshare.net/slideshow/embed_code/key/L8nHn384zhwuNO"
        frameBorder="0"
        marginWidth="0"
        marginHeight="0"
        scrolling="no"
        title="Presentation"
        className={styles.iframe}
      >
        {' '}
      </iframe>
    </div>
  );
};
export default InvestorPresentation;
