import React, {Component} from 'react';
import QuestionComponent from './QuestionComponent';
import questions from '../../data/questions';
import styles from './InvestNow.module.css';
import Docusign from './Docusign';
import Title from './Title';
import questionDictionary from '../../data/questionDictionary';
import docusignsDictionary from '../../data/docusignsDictionary';
import DesktopButtons from './DesktopButtons';
import MobileButtons from './MobileButtons';
import docusigns from '../../data/docusigns';
import InvestNowService from './InvestNowService';



class InvestNow extends Component  {
  state = {
    dataComponent: questions['question-1'],
    url: '',
    numQuestion: 1,
    calendy:'',
  };

  lessThan250k = async (target) =>{
    const data = questionDictionary[target.value];
    InvestNowService.addAnswer(target);
    if(InvestNowService.formFinished()){
      const url = await docusignsDictionary[InvestNowService.answers].docu;
      this.setState({url:url});  
    }
    else{
      this.setState({dataComponent: questions[data.next],numQuestion: this.state.numQuestion +1});
    }
  }
  
  handleChange = async( event) => {
    if(event.target.value === '1M+'){
      this.setState({calendy: docusigns.calendy});
    }
    else{
      this.lessThan250k(event.target);
    }
  };

  goBack = async () => {
    InvestNowService.goBack();
    await this.setState({ dataComponent: questions[InvestNowService.prevQuestion], numQuestion:this.state.numQuestion -1});
  }

  renderForm = () =>{
    return <Docusign url={this.state.calendy}/>
  }

  goToLandpage = () => {
    window.location = '/';
  }

  renderQuestionComponent = (isEnabled) => {
    const question = this.state.dataComponent;
    return(
      <div className={styles.body}>
        <Title subtitle={'Complete the following questions'}/>
        <form onSubmit={this.handleSubmit} className={styles.formContainer}>
            <QuestionComponent
              questionNum={this.state.numQuestion}
              question={question.question}
              options={question.options}
              onChange={this.handleChange}
              values={InvestNowService.answers}
            />
            <DesktopButtons
              goBack={this.goBack}
              prevQuestion={InvestNowService.questions}
              goToLandpage={this.goToLandpage}
              url={this.state.url}
              isEnabled={isEnabled}
              />
            <MobileButtons
              goBack={this.goBack}
              prevQuestion={InvestNowService.questions}
              goToLandpage={this.goToLandpage}
              url={this.state.url}
              isEnabled={isEnabled}
            />
        </form>
      </div>
    );
  }

  render(){
    if(this.state.calendy){
      return this.renderForm();
    }
    return this.renderQuestionComponent(InvestNowService.formFinished());
  };
}

export default InvestNow;

