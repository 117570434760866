import React , {Component} from 'react';
import styles from './VideoLibrary.module.css';
import RightArrow from '../../images/videoLibraryRightArrow.svg';
import LeftArrow from '../../images/videoLibraryLeftArrow.svg';
import line from '../../images/videoLibraryLine.svg';
import VideoLibraryMobile from './VideoLibraryMobile';
import isMobile from '../../helpers/isMobile';
import VideoService from '../../service';
import NavigationArrow from './NavigationArrow';

class VideoLibrary extends Component {

  state = {
    currentVideo: VideoService.getVideo(0),
  }

  changeToPreviousVideo = (e) =>{
    let previousVideo = VideoService.getPreviousVideo(this.state.currentVideo);
    this.changeCurrentVideo( previousVideo , e);
  }
  changeToNextVideo = (e) =>{
    let nextVideo = VideoService.getNextVideo(this.state.currentVideo);
    this.changeCurrentVideo(nextVideo , e);
  }
  changeToSelectedVideo =(video,e)=>{
    let selectedVideo = VideoService.getVideo(video.id);
    this.changeCurrentVideo(selectedVideo,e);
  }
  changeCurrentVideo = (video,e) => {
    e.stopPropagation();
    this.setState({currentVideo: video})
  }

  renderMainVideo = () => {
    return(
      <div className={styles.playingVideo}>
        <iframe
          title="playingVideo"
          src={this.state.currentVideo.link}  
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          className={styles.mainIframe}
        />
      </div>
    )
  }
  renderMiddleLineAndTexts = () =>{
    const space = ' ';
    const title = `(${space}${VideoService.getAllVideos().length}${space}videos${space})`;
    return(
      <div className={styles.middleLineAndText}>
        <img className={styles.line} src={line} alt=""/>
        <span className={styles.videoLibraryText}>Video Library </span>
        <span className={styles.amountOfVideos}>{title}</span>
      </div>
    )
  }

  renderOtherVideos = (videos) =>{
    let otherVideos = VideoService.getOtherVideos(this.state.currentVideo);
    return <div className={styles.otherVideosContainer}>{otherVideos.map( video => this.renderOneVideo(video))}</div>
  }

  renderOneVideo = (video) =>{
    return(
      <div key={video.id} className={styles.otherVideoContainer}>
        <button className={styles.otherVideoButton} onClick={(e) => this.changeToSelectedVideo(video,e)}>
          <div className={styles.overlay}>{}</div>
          <iframe
            title="otherVideo"
            src={VideoService.getVideo(video.id).link}  
            frameBorder="0"
            className={styles.otherVideoIframe}
          /> 
        </button>
        <h1 className={styles.header}>{video.header}</h1>
        <p className={styles.description}>{video.description}</p>
      </div>
    )
  }
  
  render(){
  if(isMobile()){
    return <VideoLibraryMobile/>
  }
  return (
    <div className={styles.body}>
      <div className={styles.arrowsAndMainVideo}>
        <NavigationArrow image={LeftArrow} changeVideo={(e)=>this.changeToPreviousVideo(e)}/>
        {this.renderMainVideo()}
        <NavigationArrow image={RightArrow} changeVideo={(e)=>this.changeToNextVideo(e)}/>
      </div>
      {this.renderMiddleLineAndTexts()}
      {this.renderOtherVideos(VideoService.getAllVideos())}
    </div>
  );
}
};

export default VideoLibrary;
