import React from 'react';
import PropTypes from 'prop-types';
import styles from './InvestNow.module.css';
import line from '../../images/videoLibraryLine.svg';

const Title = props => {
  const { subtitle } = props;
  return (
    <div className={styles.title}>
      <img className={styles.line} src={line} alt="" />
      <h1>
        Let&apos;s discover your
        <br />
        investment profile
      </h1>
      <h3 className={styles.subtitle}>{subtitle}</h3>
    </div>
  );
};

Title.propTypes = {
  subtitle: PropTypes.string
};
Title.defaultProps = {
  subtitle: ''
};

export default Title;
