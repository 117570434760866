export const setInvestNowClass = (path, location, styles) => {
  if (location.pathname === '/InvestNow') {
    return styles.investNowClicked;
  }
  return styles.investNow;
};

export const setOthersClass = (path, location, styles) => {
  if (path === location.pathname) {
    return path === '/InvestorPresentation'
      ? styles.investorPresentationSelected
      : styles.linkSelected;
  }
  return path === '/InvestorPresentation' ? styles.investorPresentation : styles.link;
};
