import docusigns from './docusigns';

const docusignsDictionary = {
  'less 100k,individual,1signer': { docu: docusigns.A },
  'less 100k,individual,2signers': { docu: docusigns.B },
  '250 - 1M,individual,1signer': { docu: docusigns.HNWA },
  '250 - 1M,individual,2signers': { docu: docusigns.HNWB },
  'less 100k,entity,Corp or LLC,1signer': { docu: docusigns.E },
  'less 100k,entity,Corp or LLC,2signers': { docu: docusigns.F },
  'less 100k,entity,Trust,1signer': { docu: docusigns.G },
  'less 100k,entity,Trust,2signers': { docu: docusigns.H },
  'less 100k,entity,Partnership,1signer': { docu: docusigns.I },
  'less 100k,entity,Partnership,2signers': { docu: docusigns.J },
  '100k - 249k,individual,1signer': { docu: docusigns.A },
  '100k - 249k,individual,2signers': { docu: docusigns.B },
  '250 - 1M,entityOver250,1signer': { docu: docusigns.HNWC },
  '250 - 1M,entityOver250,2signers': { docu: docusigns.HNWD },
  '100k - 249k,entity,Corp or LLC,1signer': { docu: docusigns.E },
  '100k - 249k,entity,Corp or LLC,2signers': { docu: docusigns.F },
  '100k - 249k,entity,Trust,1signer': { docu: docusigns.G },
  '100k - 249k,entity,Trust,2signers': { docu: docusigns.H },
  '100k - 249k,entity,Partnership,1signer': { docu: docusigns.I },
  '100k - 249k,entity,Partnership,2signers': { docu: docusigns.J }
};

export default docusignsDictionary;
