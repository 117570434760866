import React, {Component} from 'react';
import { Link , withRouter } from 'react-router-dom';
import styles from './MenuMobile.module.css';
import fullLogo from '../../../images/fullLogo.svg';
import cross from '../../../images/cross.svg';
import hamburguerMenu from '../../../images/hamburguerMenu.svg';

class MenuMobile extends Component {
  state = {
    open: false
  };
  
  handleMenu (status) {
    this.setState({open: status});
    this.props.onMenuToggled(status);
  }
  setMenuItem = (path, label) => {
    return (
        <Link
          className={path === '/InvestNow' ? styles.investNow : styles.link}        
          to={path}
          onClick={()=>this.handleMenu(false)}
        >
          {label}
        </Link>
    );
  };
  renderLogo = () => {
    return(
      <Link className={styles.logo} to="/" onClick={()=>this.handleMenu(false)}>
        <span>
          <img src={fullLogo} alt=""/>
        </span>
      </Link>  
    )
  }
  renderMenuButtons = ()=>{
    return(
      <div className={styles.containerOpen}>
      {this.renderLogo()}     
      <button className ={styles.menuButton} onClick={()=>this.handleMenu(false)}>  
         <img src={cross} alt=""/>
      </button>
     </div>
    );

  }
  renderMenuOpen =() => {
    return(
      <div>       
         {this.renderMenuButtons()} 
         <div className={styles.menuItemsContainer} >
            {this.setMenuItem('/InvestorPresentation', 'INVESTOR PRESENTATION')}
            {this.setMenuItem('/VideoLibrary', 'VIDEO LIBRARY')}
            {this.setMenuItem('/ContactUs', 'CONTACT US')}
            {this.setMenuItem('/InvestNow', 'INVEST NOW')}
          </div>         
       </div> 
    );
  }
  renderMenuClose =() => {
    return(  
          <div className={styles.container}>
            {this.renderLogo()}   
            <button className ={styles.menuButton} onClick={()=>this.handleMenu(true)}>  
              <img src={hamburguerMenu} alt=""/>
            </button>
           </div>
      );
  }
  render(){
    if(this.state.open){
      return this.renderMenuOpen();     
    } else { 
      return this.renderMenuClose();
    }
  };
};
export default withRouter(MenuMobile);