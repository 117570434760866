import React from 'react';
import { Link } from 'react-router-dom';
import vector from '../images/Vector.svg';
import styles from '../components/HowToInvest/HowToInvest.module.css';

const steps = [
  {
    numStep: 1,
    title: ' Review Fund V Documents & Materials',
    text: selected => {
      return (
        <div className={selected ? styles.stepText : styles.hide}>
          <p className={styles.textParagraph}>
            a. Please review the Fund documents on the left under &quot;Fund V Information&ldquo;
          </p>
          <p className={styles.textParagraph}>
            b. Review the Investor Presentation, our investor videos, and more inside this portal.
          </p>
        </div>
      );
    }
  },
  {
    numStep: 2,
    title: ' Determine Commitment Amount & Complete Subscription Booklet',
    text: selected => {
      return (
        <div className={selected ? styles.stepText : styles.hide}>
          <p className={styles.textParagraph}>
            a. Begin your investment by clicking on “Invest Now” at the top right of the menu bar or
            click here.(​Invest Now button)
          </p>
          <p className={styles.textParagraph}>
            b. The Fund V documents you must review and complete to invest in the Fund depend
            primarily on the amount you plan to commit to the Fund. The commitment levels and
            associated documents will be determined by the questionnaire from the “Invest Now” page
            and are as follows:
          </p>
          <p className={styles.indentParagraph}>
            • Investors committing $50,000 to $249,999 should review and complete Griffis Premium
            Apartment Fund V (AI - Co-Invest) documents
          </p>
          <p className={styles.indentParagraph}>
            • Investors committing greater than $250,000 should review and complete either Griffis
            Premium Apartment Fund V (HNW) or Griffis Premium Apartment Fund V (AI - Co-Invest)
            documents depending upon investor accreditation level
          </p>
          <p className={styles.indentParagraph}>
            • If you are investing via the Invest Now section, the questionnaire will determine your
            documents automatically based on this criteria. c. You will receive email updates and
            copies upon completion of the Docu
          </p>
          <p className={styles.textParagraph}>
            c. You will receive email updates and copies upon completion of the Docusign.
          </p>
          <p className={styles.textParagraph}>
            d. If you prefer, you can also print, sign, date and upload the documents using the
            right menu items to complete the subscription booklet.
          </p>
          <div className={styles.link}>
            <Link className={styles.linkInvestButton} to="/InvestNow">
              <button type="submit" className={styles.startInvestmentButton}>
                <span className={styles.buttonText}>INVEST</span>
                <img className={styles.vectorButton} src={vector} alt="" />
              </button>
            </Link>
          </div>
        </div>
      );
    }
  },
  {
    numStep: 3,
    title: ' Upload your Investor Identification Documents',
    text: selected => {
      return (
        <div className={selected ? styles.stepText : styles.hide}>
          <p className={styles.textParagraph}>
            a. You must upload the following items to the data room. You can also email them to
            grdirect@griffisresidential.com with subject: Investor ID Docs
          </p>
          <p className={styles.textParagraph}>
            b. Upload the respective documents based on your investment profile:
          </p>
          <div>
            <p className={styles.indentParagraphStep3}>• Individuals​:</p>
            <p className={styles.indentSpan}>
              1. Copy of passport / national ID photo, OR driver’s license
            </p>
            <p className={styles.indentSpan}>
              2. Proof of residence address (e.g. photo driving license or bank /credit card /
              building society / mortgage / statement or utility bill dated within the last 3
              months.)
            </p>
          </div>
          <div>
            <p className={styles.indentParagraphStep3}>
              • Corporation, Limited Company, or Limited Liability Company​:
            </p>
            <p className={styles.indentSpan}>
              1. Copy of the certificate of registration, articles of association, bylaws or other
              constituent documents of the Applicant OR evidence ofregistration from commercial
              registry or an extract thereof, noting the name, legal form and registered address of
              the Applicant.
            </p>
            <div className={styles.indentSpan}>
              2. Please provide the following for any person that controls, directly or indirectly
              25% or more of the capital, profits or voting rights of the Applicant:
              <p className={styles.indentLevelThree}>
                a. Copy of passport/national identification card/photo driving license.
              </p>
              <p className={styles.indentLevelThree}>
                b. Proof of residential address (e.g., photo driving license or bank/credit
                card/building society/mortgage/statement or utility bill dated within the last 3
                months).
              </p>
            </div>
            <p className={styles.indentSpan}>
              3. Evidence of Signing Authority (e.g., authorized signatory list, board resolutions,
              incumbency certificate or commercial registry extract (if contains signatory list)).
            </p>
          </div>
          <div>
            <p className={styles.indentParagraphStep3}>• Trust or Nominee:</p>
            <p className={styles.indentSpan}>
              1. Copy of trust certification or relevant extract from trust agreement or trust deed
              or other constituent document (or equivalent) or commercial register extract (if
              applicable), establishing full name of the trust; nature, purpose and object of the
              trust; country of establishment; names of all trustees; names of all beneficiaries or
              description of classes of beneficiaries; and names of all protectors or controllers.
            </p>
            <div className={styles.indentSpan}>
              2. Proof of residence address (e.g. photo driving license or bank /credit card /
              building society / mortgage / statement or utility bill dated within the last 3
              months.)
              <br />
              <p className={styles.indentLevelThree}>
                a. Copy of passport/national identification card/photo driving license
              </p>
              <p className={styles.indentLevelThree}>
                b. Proof of residential address (e.g., photo driving license or bank/credit
                card/building society/mortgage/statement or utility bill dated within the last 3
                months).
              </p>
            </div>
          </div>
        </div>
      );
    }
  },
  {
    numStep: 4,
    title: ' Griffis Residential Review',
    text: selected => {
      return (
        <div className={selected ? styles.stepText : styles.hide}>
          <p className={styles.textParagraph}>
            a. Once we receive your completed and signed documents from the investor portal or by
            email, we will confirm receipt and will review the documents.
          </p>
        </div>
      );
    }
  },
  {
    numStep: 5,
    title: ' Accredited Investor Verification',
    text: selected => {
      return (
        <div className={selected ? styles.stepText : styles.hide}>
          <p className={styles.textParagraph}>
            a. To satisfy recent SEC regulations for Fund compliance, if you are a natural person
            (individual or individual and spouse), you will be aske
          </p>
          <p className={styles.indentParagraph}>
            • VerifyInvestor.com​ — If you would like​ ​ VerifyInvestor.com​ to perform your
            verification, please let us know and we will initiate the verification process via
            email. This will be completed at no cost to you.​ ​ VerifyInvestor.com​ is the leading
            provider of accreditation verification through an on-line process that can be completed
            in as little as ten minutes.
          </p>
          <p className={styles.indentParagraph}>
            • Provide third-party verification using the letter template located in the Fund V
            Information “Subscription Booklet section on the portal or provided in hard or soft copy
            from:
          </p>
          <div className={styles.indentSpan}>• A registered broker-dealer;</div>
          <p className={styles.indentSpan}>
            • An investment adviser registered with the SEC, A licensed attorney
          </p>
          <br />
          <span className={styles.indentSpan}>OR</span>
          <p className={styles.indentSpan}>• A certified public accountant</p>
        </div>
      );
    }
  },
  {
    numStep: 6,
    title: ' Griffis Residential Acceptance',
    text: selected => {
      return (
        <div className={selected ? styles.stepText : styles.hide}>
          <p className={styles.textParagraph}>
            a. Once verification is confirmed, we will provide you with a copy of the accepted
            Subscription Booklet by uploading a copy to your investor portal (document will be found
            under “Your Fund V Documents/Signed Agreements”).
          </p>
        </div>
      );
    }
  }
];
export default steps;
