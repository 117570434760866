import React, { Component } from 'react';
import styles from './HowToInvest.module.css';
import steps from '../../data/steps';
import godownVector from '../../images/godownVector.svg';
import goUpVector from '../../images/goUpVector.svg';
import line from '../../images/videoLibraryLine.svg';


class HowToInvest extends Component {
  state = {
    id: 0,
  }

  handleOpen = async (id)=> {
    this.setState({id:id});
  }

  handleClose = () => {
    this.setState({id:0});
  }

  isOpen = (numStep) => {
    if(numStep === this.state.id){
      return true;
    }
    return false;
  }

  renderStep = (step) => {
    const selected = this.isOpen(step.numStep);
    const stepText = step.text;
    return (
      <div className={styles.step} key={step.numStep}>
          <h4 className={styles.stepContainer}>
            <strong className={styles.numStep}>{step.numStep}</strong>
            <p className={selected ? styles.stepTitle : styles.stepTitleNotSelected}>{step.title}</p>
            <button type="submit" onClick={()=> this.handleOpen(step.numStep)} className={selected ? styles.hide : styles.toogleTextButton}>
              <img src={godownVector} alt="" className={styles.vector} />
            </button>
            <button type="submit" onClick={()=> this.handleClose()} className={selected ? styles.toogleTextButton : styles.hide}>
              <img src={goUpVector} alt="" className={styles.vector} />
            </button>
          </h4>
          {stepText(selected)}
    </div>
    );
  }

  render() {
    return (
      <div className={styles.body}>
        <img className={styles.line} src={line} alt="" />
        <h1 className={styles.howToTitle }>How to invest</h1>
        <h3 className={styles.subtitle }>Our process</h3>
        <div className={styles.steps}>
          {steps.map(step =>(
            this.renderStep(step)
          ))}
        </div>
      </div>
    );
  }
}

export default HowToInvest;
