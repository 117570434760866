import docusignsDictionary from '../../data/docusignsDictionary';

const InvestNowService = {
  docusignsDictionary,
  answers: [],
  questions: [],
  prevQuestion: '',
  numQuestion: 1,

  addAnswer: async target => {
    if (!InvestNowService.formFinished()) {
      InvestNowService.answers.push(target.value);
      InvestNowService.numQuestion += 1;
      InvestNowService.questions.push(target.name);
    } else {
      InvestNowService.answers[InvestNowService.numQuestion - 2] = target.value;
    }
  },

  goBack: async () => {
    if (InvestNowService.formFinished()) {
      InvestNowService.prevQuestion = InvestNowService.questions.pop();
      InvestNowService.answers.pop();
      InvestNowService.numQuestion -= 1;
    }
    InvestNowService.numQuestion -= 1;
    InvestNowService.prevQuestion = InvestNowService.questions.pop();
    InvestNowService.answers.pop();
  },

  formFinished: () => {
    if (InvestNowService.docusignsDictionary[InvestNowService.answers]) {
      return true;
    }
    return false;
  }
};

export default InvestNowService;
