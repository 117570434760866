import React from 'react';
import styles from './VideoLibraryMobile.module.css';
import VideoService from '../../service';
import line from '../../images/videoLibraryLine.svg';

const renderMiddleLineAndTexts = allVideos => {
  const space = ' ';
  const title = `(${space}${allVideos.length}${space}videos${space})`;
  return (
    <div className={styles.middleLineAndText}>
      <img className={styles.line} width="40px" src={line} alt="" />
      <span className={styles.videoLibraryText}>Video Library </span>
      <span className={styles.amountOfVideos}>{title}</span>
    </div>
  );
};
const renderSingleVideo = video => {
  return (
    <div key={video.id} className={styles.iframeContainer}>
      <iframe
        title="playingVideo"
        src={video.link}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        className={styles.iframe}
      />
      <h1 className={styles.header}>{video.header}</h1>
      <p className={styles.description}>{video.description}</p>
    </div>
  );
};
const renderAllVideos = allVideos => {
  return (
    <div className={styles.allVideosContainer}>
      {allVideos.map(video => renderSingleVideo(video))}
    </div>
  );
};
const VideoLibraryMobile = () => {
  const allVideos = VideoService.getAllVideos();
  return (
    <div>
      {renderMiddleLineAndTexts(allVideos)}
      {renderAllVideos(allVideos)}
    </div>
  );
};

export default VideoLibraryMobile;
