const videos = [
  {
    id: 0,
    link: 'https://player.vimeo.com/video/354753887',
    header: 'Asset Management',
    description:
      'Up am intention on dependent questions oh elsewhere september. No betrayed pleasure possible jointure we in throwing. And can event rapid any shall woman green. ',
    thumbnail: ''
  },
  {
    id: 1,
    link: 'https://player.vimeo.com/video/353461912',
    header: 'Affordability',
    description:
      'Picture removal detract earnest is by. Esteems met joy attempt way clothes yet demesne tedious. Replying an marianne do it an entrance advanced. ',
    thumbnail: ''
  },
  {
    id: 2,
    link: 'https://player.vimeo.com/video/353463600',
    header: 'Investment Strategy',
    description:
      'Frankness applauded by supported ye household. Collected favourite now for for and rapturous repulsive consulted.',
    thumbnail: ''
  },
  {
    id: 3,
    link: 'https://player.vimeo.com/video/356218157',
    header: 'Why Multifamily',
    description:
      'On projection apartments unsatiable so if he entreaties appearance. Rose you wife how set lady half wish. Hard sing an in true felt. ',
    thumbnail: ''
  },
  {
    id: 4,
    link: 'https://player.vimeo.com/video/354306983',
    header: 'Value Creation',
    description:
      'On projection apartments unsatiable so if he entreaties appearance. Rose you wife how set lady half wish. Hard sing an in true felt. ',
    thumbnail: ''
  },
  {
    id: 5,
    link: 'https://player.vimeo.com/video/353831027',
    header: 'Market Cycles',
    description:
      'On projection apartments unsatiable so if he entreaties appearance. Rose you wife how set lady half wish. Hard sing an in true felt. ',
    thumbnail: ''
  },
  {
    id: 6,
    link: 'https://player.vimeo.com/video/353613407',
    header: 'Resident Experience',
    description:
      'On projection apartments unsatiable so if he entreaties appearance. Rose you wife how set lady half wish. Hard sing an in true felt. ',
    thumbnail: ''
  },
  {
    id: 7,
    link: 'https://player.vimeo.com/video/353460180',
    header: 'Tax Advantages',
    description:
      'On projection apartments unsatiable so if he entreaties appearance. Rose you wife how set lady half wish. Hard sing an in true felt. ',
    thumbnail: ''
  }
];

export default videos;
