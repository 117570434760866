const docusigns = {
  A:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=6d83ff5b-3630-4117-b3f8-41690cbdd948&env=na3-eu1&v=2',
  B:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=c0815fba-ebc0-4189-8d9e-e7ff272a4bbd&env=na3-eu1&v=2',
  E:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=431e5eef-c1e2-4cac-aa33-6dff1f829d40&env=na3-eu1&v=2',
  F:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=72aca439-08f0-4fd6-8a20-bc4f6ea26b69&env=na3-eu1&v=2',
  G:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=eb392850-de7d-404b-bc1d-917d8c20caec&env=na3-eu1&v=2',
  H:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=1cb678c3-36a3-4a29-8b7f-71981c4dc8aa&env=na3-eu1&v=2',
  I:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=5035a785-bf9b-4b19-b16d-2024525f0956&env=na3-eu1&v=2',
  J:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a8df2334-a770-483b-bf3f-51c6f84effa2&env=na3-eu1&v=2',
  HNWA:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=8020489e-4be4-4897-9fba-5a32a205098e&env=na3-eu1&v=2',
  HNWB:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=32cfb1b3-477c-43c4-9a2a-8e237813b3e8&env=na3-eu1&v=2',
  HNWC:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=809a4ab7-071c-4ca0-9b25-8c7ac23611c5&env=na3-eu1&v=2',
  HNWD:
    'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=f60d58d5-6c52-46c4-8db8-556ba1df32e2&env=na3-eu1&v=2',
  calendy: 'https://calendly.com/hgriffis'
};

export default docusigns;
