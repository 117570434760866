import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './App.module.css';
import MenuDesktop from './components/Menu/MenuDesktop/MenuDesktop';
import InvestorPresentation from './components/InvestorPresentation/InvestorPresentation';
import VideoLibrary from './components/VideoLibrary/VideoLibrary';
import HowToInvest from './components/HowToInvest/HowToInvest';
import InvestNow from './components/InvestNow/InvestNow';
import ContactUs from './components/ContactUs/ContactUs';
import MenuMobile from './components/Menu/MenuMobile/MenuMobile';
import isMobile from './helpers/isMobile';

const setClass = pathname => {
  const routes = {
    '/': styles.HowToInvestClass,
    '/VideoLibrary': styles.VideoLibraryClass,
    '/ContactUs': styles.ContactUsClass,
    '/InvestorPresentation': styles.InvestorPresentationClass,
    '/InvestNow': styles.InvestNowClass
  };
  return routes[pathname];
};

class Home extends Component {
  state = {
    openedMobileMenu:false
  }
  onMenuToggled = (status) =>{
    this.setState({ openedMobileMenu: status})
  }
  render() {
    return (
      <div className={setClass(this.props.location.pathname)}>
        { isMobile() ?  <MenuMobile onMenuToggled={this.onMenuToggled}/> : <MenuDesktop />}
        <div className={ this.state.openedMobileMenu ? styles.openedMenu : styles.closedMenu}>
          <Route exact path="/" component={HowToInvest} />
          <Route path="/InvestorPresentation" component={InvestorPresentation} />
          <Route path="/VideoLibrary" component={VideoLibrary} />
          <Route path="/ContactUs" component={ContactUs} />
          <Route path="/InvestNow" component={InvestNow} />
        </div>
      </div>
    );
  }
}
Home.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired
};

const HomeComponent = withRouter(props => <Home {...props} />);

const App = () => {
  return (
    <div id="root" className={styles.rootContainer} >
        <Router>
          <HomeComponent />
        </Router>
    </div>
  );
};

export default App;
